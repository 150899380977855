'use strict';
const apollo_event_collector_default_events = [
  'mouseup',
  'change',
];
class ApolloEventCollector {

  constructor(t) {
    (this.config = t), (this.eventQueue = []);
  }
  start() {
    let t = this,
    e = document.getElementsByTagName('html')[0];
  t.config.events.forEach(function (a) {
    document.addEventListener(a, function (n) {
      if (!n.target.dataset.evtCollect) return;
      let o, c;
      if (
        n.target.dataset.evtContextDynamic &&
        'function' == typeof t.config.dynamicContextProcessor
      ) {
        let e = t.config.dynamicContextProcessor(n.target);
        (o = e.contextId), (c = e.contextData);
      } else (o = n.target.dataset.evtContextId), (c = n.target.dataset.evtContextData);
      let s = {
        eventName: a,
        elementType: n.target.nodeName,
        elementId: n.target.getAttribute('id'),
        channel: e.dataset.evtChannel,
        page: e.dataset.evtPage,
        fullURL: document.location.href,
        category: n.target.dataset.evtCategory,
        eventType: n.target.dataset.evtType,
        contextId: o,
        contextData: c,
        rawEvent: n,
      };
      'queue' === t.config.mode ? t.eventQueue.push(s) : t.config.onEvent(s);

      });
    });
  }
}
